import { View, Text, Dimensions, TouchableOpacity, Animated, Image, Pressable, TextInput, FlatList, ScrollView } from 'react-native'
import React, {useState, useRef, useEffect} from 'react'
const {width, height} = Dimensions.get('screen')
import { useNavigation } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'; 
import { useStateContext } from '../src/contexts/ContextProvider';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import Header from '../Components/Header';
import { Video } from 'expo-av';
import { ImageBackground } from 'react-native';
import * as queries from '../src/graphql/queries';
import LoginFlow from '../Components/LoginFlow';




const GamingHub = () => {

    const imgBack = 'https://images.unsplash.com/photo-1496065187959-7f07b8353c55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
    const backColour = '#e3eefa';
    const ourOrange = '#FF4F00';

    const {counting, setCounting, authed, setAuthed} = useStateContext();

    const navigation = useNavigation();
    const [ticketsall, setTicketsAll] = useState([])

    const [opt,setOpt] = useState('Profile');
    const [mainOpt, setMainOpt] = useState(false);
    const [secOpt, setSecOpt] = useState('');

    

    const fetchingTickets = async()=> {

  
        if(authed){
          const userIdd = await Auth.currentAuthenticatedUser({bypassCache: true})
    
        
    
          const todoData = await API.graphql({query: queries.listOrders, variables:  
              { filter :  
                
                { and: [ 
                    {active: {eq: 'true'}},
                    {userId: {eq: (userIdd?.attributes.sub)}},
                    {eventLocation: {eq: 'egaming'}},
                    
                    
                ]}
                }})
              const todos = todoData.data.listOrders.items
              setTicketsAll(todos)
        }
    
    
        
    }

    useEffect(()=> {
        fetchingTickets();
    }, [ticketsall, authed])



    const emptyItems = () => {
        if(ticketsall?.filter(cp => !cp.product).length === 0){
         return  (
          <View style={{width: '100%', height: '100%', alignItems: 'center', marginTop:60}}>
          <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>You Have No Events</Text>
        </View>
         )
     } 
     }

     const itemSep = () => {
        <View style={{marginTop: 10}} />
      }


     const renderEventList = ({item, index})=> {



        return(
          <Pressable 
          onHoverIn={(e)=> {}}
          style={{marginRight: 10, marginBottom: 10, elevation: 15, padding: 10, borderRadius: 10, backgroundColor: '#fcfcfc'}} onPress={()=> navigation.navigate('EventDetailsGaming', {item: item})}>
              <ImageBackground source={{uri: item.image}} imageStyle={{borderRadius: 10}} style={{width: 180, height: 180}}>
  
              </ImageBackground>
              <Text style={{fontSize: 14, marginTop: 10}}>{String(item.name).slice(0,23)}</Text>
              <Text style={{fontSize: 12, color: 'gray'}}>ZAR{Number(item.minAmount).toFixed(2)} / USD{Number(item.minAmount / 18.615042).toFixed(2)} - ({item.numPlayers})</Text>
              <Text style={{fontSize: 10, color: 'gray'}}>{item.date}</Text>
              <Text style={{fontSize: 10, color: 'gray'}}>Prize : ZAR{Number(item.prize).toFixed(2)}</Text>
              <View style={{backgroundColor: ourOrange, width: 35, height: 35, opacity: 0.99, position: 'absolute', top: 15, right:15, alignSelf: 'flex-end', padding: 5, borderRadius: 5, margin: 5, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontWeight: 'bold', color:'white', fontSize: 20}}>+</Text>
              </View>
          </Pressable>
        )
      }







    return(
        <View style={{flex: 1, backgroundColor: backColour}}>
            <ImageBackground source={{uri: imgBack}} style={{height: 150, width: width}} >
        <Animated.View style={{position: 'absolute', left: 15, top: 15}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, borderRadius: 10, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
            </ImageBackground>

            <View style={{flexDirection: 'row'}}>
                <View style={{width: '70%'}}>
            {emptyItems()}
                            <FlatList 
                                data={ticketsall}
                                renderItem={renderEventList}
                                numColumns={4}
                                style={{padding: 20}}
                                ItemSeparatorComponent={itemSep}
                            />
                </View>

                <View style={{width: '30%'}}>
                    <View style={{margin: 15, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold', marginBottom: 10}}>Favourites</Text>

                        <View style={{height: 40, width: '100%', alignItems: 'center', paddingHorizontal: 3, justifyContent: 'space-between', backgroundColor: backColour, flexDirection: 'row', borderRadius: 10}}>
                            <Pressable 
                            onPress={()=> setOpt('Profile')}
                            style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Profile'  ? '#fcfcfc' : 'tranasparent', alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{fontSize: 10}}>PAST GAMES</Text>
                            </Pressable>

                            <Pressable 
                            onPress={()=> setOpt('Notifications')}
                            style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Notifications'  ? '#fcfcfc' : 'tranasparent', alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{fontSize: 10}}>NOTIFICATIONS</Text>
                            </Pressable> 

                            <Pressable 
                            onPress={()=> setOpt('Chat')}
                            style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Chat'  ? '#fcfcfc' : 'tranasparent', alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{fontSize: 10}}>CHAT SUPPORT</Text>
                            </Pressable>
                        </View>
                    </View>

                    <View style={{marginHorizontal: 15, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10}}>
                    {
                         opt === 'Profile' && 

                         <View>
                                
                         <View style={{width: '100%', height: '100%', alignItems: 'center', marginTop:0}}>
                             <Text style={{fontSize: 16, fontWeight: 'bold', opacity: 0.6}}>You Have No Events</Text>
                         </View>

                     </View>


                    }

                    {
                         opt === 'Notifications' && 

                            <View>
                                
                                <View style={{width: '100%', height: '100%', alignItems: 'center', marginTop:0}}>
                                    <Text style={{fontSize: 16, fontWeight: 'bold', opacity: 0.6}}>You Have No Notifications</Text>
                                </View>

                            </View>


                    }


                            {
                                    opt === 'Chat' && 
                                    <View>
                                        <Pressable style={{height: 60, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                                            <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                                <Ionicons name= 'md-wallet-outline' size={30} />
                                            </View>

                                            <View style={{ marginLeft: 15}}>
                                                <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>Create Ticket</Text>
                                                <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Chat and solve your problem</Text>
                                            </View>

                                            <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                                                <Entypo name= 'dots-three-horizontal' size={15} />
                                            </Pressable>
                                        </Pressable>

                                    </View>
                                }


                    </View>

                </View>
            </View>

           


        </View>
    )
}

export default GamingHub