import { View, Text, Dimensions, TouchableOpacity, Animated, Image, Pressable, TextInput } from 'react-native'
import React, {useState, useRef, useEffect} from 'react'
const {width, height} = Dimensions.get('screen')
import { useNavigation } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation } from '@expo/vector-icons'; 
import { useStateContext } from '../src/contexts/ContextProvider';
import { Auth, API, graphqlOperation } from 'aws-amplify';


const Header = () => {

    const navigation = useNavigation();
    const drawer = useRef(new Animated.Value(width)).current;

    const [prof, setProf] = useState(false)

    const {counting, setCounting, authed, setAuthed, setAuthedUser, authedUser} = useStateContext();
    
   

    
    const signout = async()=> {
        try {
             await Auth.signOut().then((e)=> {
                setAuthed(false),
                navigation.navigate('Home')
             })   
        } catch (error) {
          console.error(error)  
        }
    }

    const smallfont = '#415066';
    const backColour = '#e3eefa';
    const secCol = '#91a5ba'


  return (
    <View style={{height: 80, backgroundColor: '#fcfcfc', width: width, justifyContent: 'center'}}>

        <Animated.View style={{height: 80, width: width, backgroundColor: '#fcfcfc', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 0, paddingBottom: 0}}>
            
                

            <TouchableOpacity onPress={()=> navigation.navigate('Home')} style={{height: '100%', alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: 30}}>
                <Image  source={require('../assets/dar.png')} style={{resizeMode: 'contain', width: 180, height: 80}}  />
            </TouchableOpacity>


            <Animated.View style={{height: '100%', justifyContent: 'center'}}>
                <Pressable onPress={()=> navigation.navigate('Search')} style={{height: 50, width: width / 4, borderRadius: 15, backgroundColor: backColour, flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                     <Feather name='search' size={15} style={{marginRight: 15}}/>
                     <TextInput style={{width: '80%'}} placeholder='Search by title, artist or country' editable={false}/> 
                </Pressable>
            </Animated.View>
            

         

            <View style={{flexDirection: 'row', marginRight: 10, alignItems: 'center'}}>

                {authed && 
                
                <Pressable  style={{marginRight: 25, flexDirection: 'row', alignItems: 'center'}} onPress={()=> navigation.navigate('Cart')}>
                    <Text style={{fontSize: 14, color: smallfont, marginLeft: 10}}>CART <View style={{padding: 3, borderRadius: 10, alignItems: 'center', backgroundColor: 'red'}}><Text style={{fontWeight: 'bold', color: '#fcfcfc', fontSize: 10}}>{0}</Text></View></Text>
                </Pressable>
            }
                   
    
                {/* {authed && 
                <TouchableOpacity style={{marginRight: 25, flexDirection: 'row', alignItems: 'center'}} onPress={()=> navigation.navigate('LiveStreams')}>
                    <Text style={{fontSize: 14, color: smallfont, marginLeft: 10}}>LIVE EVENTS</Text>
                </TouchableOpacity>} */}

                
{/* 
                <TouchableOpacity style={{marginRight: 25, flexDirection: 'row', alignItems: 'center'}} onPress={()=> navigation.navigate('Tv')}>
                    <Text style={{fontSize: 14, color: smallfont, marginLeft: 10}}>TV</Text>
                </TouchableOpacity> */}

                {authed && 
                
                <TouchableOpacity style={{marginRight: 25, flexDirection: 'row', alignItems: 'center'}} onPress={()=> navigation.navigate('MyTickets')}>
                <Text style={{ fontSize: 14, color: smallfont, marginLeft: 10}}>MY TICKETS</Text>
            </TouchableOpacity>
            }


                <Animated.View style={{flexDirection: 'row'}}>
                    

                {authed ? 
                    // <Pressable onHoverIn={()=> setProf(true)}  style={{height: 35, width: 120, paddingHorizontal: 15, flexDirection: 'row', borderWidth: 0, borderRadius: 5, backgroundColor: 'rgba(19, 71, 156, 0.8)', alignItems: 'center', justifyContent: 'center'}}>
                    //     <Text style={{fonts: 12, color: '#fcfcfc'}}>{authedUser.firstName}</Text>
                    //     <MaterialIcons  name='arrow-drop-down' size={20} color={'#fcfcfc'} style={{marginLeft: 10}} />
                    // </Pressable> 
                    
                    <Pressable onHoverIn={()=> setProf(true)}>
                        <View style={{flexDirection: 'row', marginRight: 25}}>
                            <View style={{height: 40, width: 40, borderRadius: 50, borderWidth: 1, borderColor: 'purple', alignItems: 'center', justifyContent: 'center'}}>
                                
                        <Image 
                        style={{height: 30, width: 30, borderRadius: 40}}
                        source={{uri: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png?20170328184010'}} />
                            </View>

                            <View style={{marginLeft: 0, height: 40, width: 20, alignItems: 'center', justifyContent: 'center'}}>
                             <MaterialIcons  name='arrow-drop-down' size={25} color={smallfont} style={{marginLeft: 10}} /> 
                            </View>


                        </View>

                    </Pressable>
                    
                    
                    : 
                    <TouchableOpacity style={{marginRight: 15, padding: 10, paddingHorizontal: 15, borderColor: secCol, flexDirection: 'row', alignItems: 'center', backgroundColor: 'transparent', borderWidth: 1, borderRadius: 10}} onPress={()=> navigation.navigate('Cart')}>
                        <Text style={{fontWeight: 'bold', fontSize: 14, color: secCol, marginLeft: 0}}>Log Me In</Text>
                    </TouchableOpacity>   
                
                
                }

                          

                {prof && 
                
                <Pressable  onHoverIn={()=> setProf(true)} onHoverOut={()=> setProf(false)}>
                    <Animated.View style={{paddingVertical: 10, width: 150, zIndex: 5000, marginTop: 80, paddingLeft: 15, backgroundColor: '#fcfcfc', alignItems: 'center', borderRadius: 10, justifyContent: 'center', position: 'absolute', bottom: -100, right: 5}}>
                   
                    <Pressable 
                    onPress={()=> {setProf(false)}}
                    onHoverIn={()=> setProf(true)}
                    style={{marginTop: 5, borderTopWidth: 0.5, width: '100%', flexDirection: 'row', height: 30, alignItems: 'center', borderColor: 'rgba(245, 245, 245, 0.7)'}}>
                       <Feather name= 'user-check' size={15} style={{marginRight: 5, width: '15%'}} />
                        <Text style={{fontSize: 12}}>Profile</Text>
                    </Pressable> 
                    <Pressable 
                    onPress={()=> {setProf(false)}}
                    onHoverIn={()=> setProf(true)}
                    style={{marginTop: 5, borderTopWidth: 0.5, width: '100%', flexDirection: 'row', height: 30, alignItems: 'center', borderColor: 'rgba(245, 245, 245, 0.7)'}}>
                       <Feather name= 'archive' size={15} style={{marginRight: 5, width: '15%'}} />
                        <Text style={{fontSize: 12}}>FAQs</Text>
                    </Pressable>   
                    <Pressable 
                    onPress={()=> {signout(), setProf(false)}}
                    onHoverIn={()=> setProf(true)}
                    style={{marginTop: 5, borderTopWidth: 0.5, width: '100%', flexDirection: 'row', height: 30, alignItems: 'center', borderColor: 'rgba(245, 245, 245, 0.7)'}}>
                       <Feather name='settings' size={15} style={{marginRight: 5, width: '15%'}} />
                        
                        <Text style={{fontSize: 12}}>Sign Out</Text>
                    </Pressable>
      
                    </Animated.View>
                </Pressable>
                    }


                    </Animated.View>

            </View>
            
            
        </Animated.View>

       
    </View>
  )
}

export default Header