import { StyleSheet, Text, View, ImageBackground, Dimensions, Animated, TouchableOpacity, Image, ScrollView } from 'react-native'
import React, {useState, useEffect} from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons'; 
import SvgQRCode from 'react-native-qrcode-svg'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { getOrder } from '../src/graphql/queries';

const {width, height} = Dimensions.get('screen');



const TicketDetails = () => {

    const route = useRoute().params
    const routeInfo = route.item

    const navigation = useNavigation()
    const ourOrange = '#FF4F00';

    let logoFromFile = require('../assets/iconsmsall.png');


    const [orderDetails, setOrderDetails] = useState();

    const gettingTheOrder  = async () =>{

        
        try {
            const gettinOrder  = await API.graphql(graphqlOperation(getOrder, { id: routeInfo?.id}))
            const orderDetailsa = gettinOrder.data.getOrder 
  
            setOrderDetails(orderDetailsa)
  
          } catch (error) {
            console.error(error)
          } 
    }


    useEffect(()=> {
     gettingTheOrder()
    },[orderDetails])



    return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <ImageBackground source={{uri: routeInfo.image}} style={{height: height / 3, width: width}} >
        <Animated.View style={{position: 'absolute', left: 0, top: 0}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
      </ImageBackground>
      

       

        <Animated.View style={{height: 60, width: width, bacc: 'rgba(0,0,0,0.5)', justifyContent: 'center', paddingHorizontal: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>{routeInfo.name || routeInfo.title}</Text>
        </Animated.View>
        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />
        <ScrollView showsVerticalScrollIndicator={false} style={{backgroundColor: '#edf1f2'}}>
        
        <View style={{backgroundColor: '#fcfcfc', alignSelf: 'center', padding: 15, width: width / 2}}>


        <View style={{flexDirection: 'row', alignSelf: 'center', padding: 15}}>
            <Animated.View style={{padding: 15, width: '40%', marginRight: 30}}>
                <Text style={{fontWeight: 'bold', fontSize: 16}}>QR CODE</Text>
                <Text style={{fontSize: 12, color: 'gray'}}>Show this to gain admission</Text>

                <View style={{marginTop: 0}}>
            
            <View style={{alignItems: 'center', marginTop: 20}}>
                <SvgQRCode value={routeInfo.id} size={width/6} />
                        <View style={{position: 'absolute', top: 100}}>
                            {orderDetails?.scanned && 
                                <View style={{padding: 20, borderRadius: 20, opacity: 0.4, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center'}}>
                                    <Text style={{fontSize: 40, fontWeight: 'bold', color: '#fcfcfc'}}>SCANNED</Text>
                                </View>
                            }
                        </View>
                        
            </View>
                


                </View>
            </Animated.View>

            <View style={{width: 5, height: height / 4, backgroundColor: '#edf1f2', marginRight: 30, marginLeft: 30}} />

            <Animated.View style={{padding: 15, width: '40%'}}>
            <Text style={{fontWeight: 'bold', fontSize: 16}}>Ticket Details</Text>
            <Text style={{fontSize: 12, color: 'gray'}}>Details on the arrangements of your ticket</Text>

            <Animated.View style={{padding: 15}}>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', marginTop: 25}}>
                <View style={{flexDirection: 'row', width: '45%'}}>
                    <Feather name='calendar' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>{routeInfo.eventDate || `--`}</Text>
                </View>

                <View style={{flexDirection: 'row'}}>
                    <Feather name='clock' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>{routeInfo.eventTime || `--`}</Text>
                </View>
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'flex-start', marginTop: 15}}>
                <View style={{flexDirection: 'row', width: '45%'}}>
                    <Feather name='check-circle' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>Admiting {routeInfo.qty || `0`}</Text>
                </View>
                            
                <View style={{flexDirection: 'row'}}>
                    <Feather name='map' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>{routeInfo.address || `--`}</Text>
                </View>
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'flex-start', marginTop: 15}}>
                <View style={{flexDirection: 'row', width: '45%'}}>
                    <Feather name='camera' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>Cameras</Text>
                </View>

                <View style={{flexDirection: 'row'}}>
                    <Feather name='alert-triangle' size={12} />
                    <Text style={{fontSize: 12, marginLeft: 15}}>{routeInfo.agegroup || `All`}</Text>
                </View>
            </View>

        </Animated.View>       
            </Animated.View>
        </View>

        
      


            
        </View>

        </ScrollView>

    </View>
  )
}

export default TicketDetails

const styles = StyleSheet.create({})