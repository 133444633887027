/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      city
      country
      address
      description
      headlineAct
      eventDate
      eventTime
      campaignId
      ownerId
      startStatus
      image
      EventDays {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      city
      country
      address
      description
      headlineAct
      eventDate
      eventTime
      campaignId
      ownerId
      startStatus
      image
      EventDays {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      city
      country
      address
      description
      headlineAct
      eventDate
      eventTime
      campaignId
      ownerId
      startStatus
      image
      EventDays {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventDay = /* GraphQL */ `
  mutation CreateEventDay(
    $input: CreateEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    createEventDay(input: $input, condition: $condition) {
      id
      title
      event {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        startStatus
        image
        createdAt
        updatedAt
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventEventDaysId
      __typename
    }
  }
`;
export const updateEventDay = /* GraphQL */ `
  mutation UpdateEventDay(
    $input: UpdateEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    updateEventDay(input: $input, condition: $condition) {
      id
      title
      event {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        startStatus
        image
        createdAt
        updatedAt
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventEventDaysId
      __typename
    }
  }
`;
export const deleteEventDay = /* GraphQL */ `
  mutation DeleteEventDay(
    $input: DeleteEventDayInput!
    $condition: ModelEventDayConditionInput
  ) {
    deleteEventDay(input: $input, condition: $condition) {
      id
      title
      event {
        id
        name
        city
        country
        address
        description
        headlineAct
        eventDate
        eventTime
        campaignId
        ownerId
        startStatus
        image
        createdAt
        updatedAt
        __typename
      }
      tickets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventEventDaysId
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      campaignId
      eventId
      name
      amount
      description
      qty
      eventDay {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
        __typename
      }
      content
      active
      suspendedByUser
      createdAt
      updatedAt
      eventDayTicketsId
      __typename
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      campaignId
      eventId
      name
      amount
      description
      qty
      eventDay {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
        __typename
      }
      content
      active
      suspendedByUser
      createdAt
      updatedAt
      eventDayTicketsId
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      campaignId
      eventId
      name
      amount
      description
      qty
      eventDay {
        id
        title
        createdAt
        updatedAt
        eventEventDaysId
        __typename
      }
      content
      active
      suspendedByUser
      createdAt
      updatedAt
      eventDayTicketsId
      __typename
    }
  }
`;
export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      active
      bank
      branchNumber
      accountNumber
      bankCountry
      SwiftNumber
      withdrawnAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      active
      bank
      branchNumber
      accountNumber
      bankCountry
      SwiftNumber
      withdrawnAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      active
      bank
      branchNumber
      accountNumber
      bankCountry
      SwiftNumber
      withdrawnAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVendorData = /* GraphQL */ `
  mutation CreateVendorData(
    $input: CreateVendorDataInput!
    $condition: ModelVendorDataConditionInput
  ) {
    createVendorData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVendorData = /* GraphQL */ `
  mutation UpdateVendorData(
    $input: UpdateVendorDataInput!
    $condition: ModelVendorDataConditionInput
  ) {
    updateVendorData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVendorData = /* GraphQL */ `
  mutation DeleteVendorData(
    $input: DeleteVendorDataInput!
    $condition: ModelVendorDataConditionInput
  ) {
    deleteVendorData(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      profileComplete
      idNumber
      idcountryIssue
      businessName
      businessAddress
      idImage
      balance
      pendingBalance
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      ownerId
      qty
      title
      image
      amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      ownerId
      qty
      title
      image
      amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      ownerId
      qty
      title
      image
      amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      ownerId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      orderAmount
      paymentStatus
      paymentReference
      fullName
      country
      active
      scanned
      eventDate
      eventTime
      eventLocation
      bookingUserName
      eventStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      ownerId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      orderAmount
      paymentStatus
      paymentReference
      fullName
      country
      active
      scanned
      eventDate
      eventTime
      eventLocation
      bookingUserName
      eventStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      ownerId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      orderAmount
      paymentStatus
      paymentReference
      fullName
      country
      active
      scanned
      eventDate
      eventTime
      eventLocation
      bookingUserName
      eventStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrderTicket = /* GraphQL */ `
  mutation CreateOrderTicket(
    $input: CreateOrderTicketInput!
    $condition: ModelOrderTicketConditionInput
  ) {
    createOrderTicket(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      ownerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrderTicket = /* GraphQL */ `
  mutation UpdateOrderTicket(
    $input: UpdateOrderTicketInput!
    $condition: ModelOrderTicketConditionInput
  ) {
    updateOrderTicket(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      ownerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrderTicket = /* GraphQL */ `
  mutation DeleteOrderTicket(
    $input: DeleteOrderTicketInput!
    $condition: ModelOrderTicketConditionInput
  ) {
    deleteOrderTicket(input: $input, condition: $condition) {
      id
      eventId
      campaignId
      seatId
      email
      vendorId
      userId
      qty
      title
      image
      amount
      ownerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      firstName
      email
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      banner
      secOne
      secTwo
      secThree
      secFour
      secFive
      startStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      banner
      secOne
      secTwo
      secThree
      secFour
      secFive
      startStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      banner
      secOne
      secTwo
      secThree
      secFour
      secFive
      startStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTransactions = /* GraphQL */ `
  mutation CreateTransactions(
    $input: CreateTransactionsInput!
    $condition: ModelTransactionsConditionInput
  ) {
    createTransactions(input: $input, condition: $condition) {
      id
      userId
      amount
      inOrOut
      approved
      commment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTransactions = /* GraphQL */ `
  mutation UpdateTransactions(
    $input: UpdateTransactionsInput!
    $condition: ModelTransactionsConditionInput
  ) {
    updateTransactions(input: $input, condition: $condition) {
      id
      userId
      amount
      inOrOut
      approved
      commment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTransactions = /* GraphQL */ `
  mutation DeleteTransactions(
    $input: DeleteTransactionsInput!
    $condition: ModelTransactionsConditionInput
  ) {
    deleteTransactions(input: $input, condition: $condition) {
      id
      userId
      amount
      inOrOut
      approved
      commment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      userId
      open
      openCustomer
      userName
      adminName
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      userId
      open
      openCustomer
      userName
      adminName
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      userId
      open
      openCustomer
      userName
      adminName
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChats = /* GraphQL */ `
  mutation CreateChats(
    $input: CreateChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    createChats(input: $input, condition: $condition) {
      id
      userId
      read
      userName
      adminName
      subject
      message
      attachmentUrl
      ChatRoom
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChats = /* GraphQL */ `
  mutation UpdateChats(
    $input: UpdateChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    updateChats(input: $input, condition: $condition) {
      id
      userId
      read
      userName
      adminName
      subject
      message
      attachmentUrl
      ChatRoom
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChats = /* GraphQL */ `
  mutation DeleteChats(
    $input: DeleteChatsInput!
    $condition: ModelChatsConditionInput
  ) {
    deleteChats(input: $input, condition: $condition) {
      id
      userId
      read
      userName
      adminName
      subject
      message
      attachmentUrl
      ChatRoom
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEgames = /* GraphQL */ `
  mutation CreateEgames(
    $input: CreateEgamesInput!
    $condition: ModelEgamesConditionInput
  ) {
    createEgames(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      banner
      secOne
      secTwo
      secThree
      secFour
      secFive
      startStatus
      date
      time
      prize
      numPlayers
      numGames
      availSpots
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEgames = /* GraphQL */ `
  mutation UpdateEgames(
    $input: UpdateEgamesInput!
    $condition: ModelEgamesConditionInput
  ) {
    updateEgames(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      banner
      secOne
      secTwo
      secThree
      secFour
      secFive
      startStatus
      date
      time
      prize
      numPlayers
      numGames
      availSpots
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEgames = /* GraphQL */ `
  mutation DeleteEgames(
    $input: DeleteEgamesInput!
    $condition: ModelEgamesConditionInput
  ) {
    deleteEgames(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      minAmount
      genre
      country
      approved
      declined
      banner
      secOne
      secTwo
      secThree
      secFour
      secFive
      startStatus
      date
      time
      prize
      numPlayers
      numGames
      availSpots
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEFixture = /* GraphQL */ `
  mutation CreateEFixture(
    $input: CreateEFixtureInput!
    $condition: ModelEFixtureConditionInput
  ) {
    createEFixture(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      numPlayers
      EgamesId
      player1Id
      player1Name
      player2Id
      player2Name
      winnerId
      winnerName
      date
      time
      comment
      liveStream
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEFixture = /* GraphQL */ `
  mutation UpdateEFixture(
    $input: UpdateEFixtureInput!
    $condition: ModelEFixtureConditionInput
  ) {
    updateEFixture(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      numPlayers
      EgamesId
      player1Id
      player1Name
      player2Id
      player2Name
      winnerId
      winnerName
      date
      time
      comment
      liveStream
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEFixture = /* GraphQL */ `
  mutation DeleteEFixture(
    $input: DeleteEFixtureInput!
    $condition: ModelEFixtureConditionInput
  ) {
    deleteEFixture(input: $input, condition: $condition) {
      id
      name
      description
      image
      active
      ownerId
      numPlayers
      EgamesId
      player1Id
      player1Name
      player2Id
      player2Name
      winnerId
      winnerName
      date
      time
      comment
      liveStream
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEPlayers = /* GraphQL */ `
  mutation CreateEPlayers(
    $input: CreateEPlayersInput!
    $condition: ModelEPlayersConditionInput
  ) {
    createEPlayers(input: $input, condition: $condition) {
      id
      name
      EAId
      image
      active
      ownerId
      EgamesId
      playerId
      comment
      anydeskId
      liveStream
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEPlayers = /* GraphQL */ `
  mutation UpdateEPlayers(
    $input: UpdateEPlayersInput!
    $condition: ModelEPlayersConditionInput
  ) {
    updateEPlayers(input: $input, condition: $condition) {
      id
      name
      EAId
      image
      active
      ownerId
      EgamesId
      playerId
      comment
      anydeskId
      liveStream
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEPlayers = /* GraphQL */ `
  mutation DeleteEPlayers(
    $input: DeleteEPlayersInput!
    $condition: ModelEPlayersConditionInput
  ) {
    deleteEPlayers(input: $input, condition: $condition) {
      id
      name
      EAId
      image
      active
      ownerId
      EgamesId
      playerId
      comment
      anydeskId
      liveStream
      createdAt
      updatedAt
      __typename
    }
  }
`;
