import { View, Text, Dimensions, TouchableOpacity, Animated, Image, Pressable, TextInput, FlatList } from 'react-native'
import React, {useState, useRef, useEffect} from 'react'
const {width, height} = Dimensions.get('screen')
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Foundation } from '@expo/vector-icons'; 
import { useStateContext } from '../src/contexts/ContextProvider';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import Header from '../Components/Header';
import { Video } from 'expo-av';


const TvStream = () => {

    const route = useRoute().params
    const backColour = '#e3eefa';

    return(
        <View style={{flex: 1, backgroundColor: backColour}}>

            <Header />

            <View style={{width: width, height: height / 2, alignItems: 'center', justifyContent: 'center', paddingTop: height / 2}}>

                <Text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 15}}>{route?.title}</Text>
                

                <Video 
                    source={{uri: route?.stream}}
                    resizeMode='contain'
                    style={{width: width / 2, height: width/2}}
                    shouldPlay={true}
                    useNativeControls
                />
            </View>

            
        </View>
    )
}

export default TvStream