import { View, Text, Dimensions, ImageBackground, Image, TouchableOpacity, Animated, ScrollView } from 'react-native'
import React, {useState, useEffect} from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import * as queries from '../src/graphql/queries';
const {width, height} = Dimensions.get('screen')
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { FlashList } from '@shopify/flash-list';




const CategorySearch = () => {

    const route = useRoute().params
    const routeInfo = route.cat

    const navigation = useNavigation();

    const [events, setEvents] = useState([])

    const ourOrange = '#FF4F00';

   
    const gettingUserData = async()=> {

        const allTodos = await API.graphql({query: queries.listCampaigns, variables: {
            filter: {
                genre: {eq: routeInfo}
            }
        }})

        const eventsData = allTodos.data.listCampaigns.items
        setEvents(eventsData)
        

    }

    useEffect(() => {
      gettingUserData()
    }, [events])

    const emptyItems = () => {
      if(events?.filter(cp => !cp.product).length === 0){
       return  (
        <View style={{width: width, alignItems: 'center', marginTop:60}}>
        <Text style={{fontSize: 20, fontWeight: 'bold', opacity: 0.6}}>No {routeInfo} Events Available</Text>
      </View>
       )
   } 
   }

    const imageBack = 'https://images.unsplash.com/photo-1684523311553-117123e138ec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1633&q=80'

    const itemSeperator = ()=> {
        return(
          <View style={{height: 10, width: width}} />
        )
       }

       const renderEvents = ({item}) => {

        return(
            <TouchableOpacity style={{backgroundColor: '#fcfcfc', paddingRight: 15, borderRadius: 10, width: width / 3}} onPress={()=> navigation.navigate('EventDetails', {item: item})}>
              <View style={{height: 100, width: width - 30, backgroundColor: '#fcfcfc', paddingHorizontal: 10, borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                <Image source={{uri: item.image}} style={{height: 85, width: 85, borderRadius: 10, marginRight: 15}}/>
                <View style={{width: width - 30 - 30, height: 85}}>
                  <Text style={{width: width - 160, fontSize: 12, fontWeight: '700', marginBottom: 10}}>{item.name}</Text>
                  <Text style={{fontSize: 10, color: 'gray', textAlign: 'justify', width: width - 160}}>{String(item.description).slice(0,50)}...</Text>
                  <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6, marginTop: 10}}>From R{Number(item.minAmount).toFixed(2)}</Text>
                </View>
                
              </View>
            </TouchableOpacity>
        )
       }


  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
      <ImageBackground source={{uri: imageBack}} style={{height:100, width: width}} >
        <Animated.View style={{position: 'absolute', left: 0, top: 0}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        
      </ImageBackground>
      <Animated.View style={{height: 60, width: width,backgroundColor: '#fcfcfc', justifyContent: 'center', paddingHorizontal: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>{routeInfo}</Text>
        </Animated.View>
        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

        <View style={{height: height - 100, width: width, padding: 15, backgroundColor: '#f5f7f7'}}>
          {emptyItems()}

          {events ? 
          <View>
            
       

          <Text style={{fontWeight: 'bold', marginBottom: 20}}></Text>


          <FlashList
            showsVerticalScrollIndicator={false}
            data={events}
            renderItem={renderEvents}
            estimatedItemSize={100}
            ItemSeparatorComponent={itemSeperator}
          />
          </View> : 

          <View style={{width: width, alignItems: 'center', marginTop:60}}>
            <Text style={{fontSize: 20, fontWeight: 'bold', opacity: 0.6}}>No {routeInfo} events available</Text>
          </View>

        }
        </View>




    </View>
  )
}

export default CategorySearch