import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Animated } from 'react-native'
import React from 'react';
import Header from '../Components/Header';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
const {width, height} = Dimensions.get('screen')
import { useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';


const Downloads = () => {

    const ourOrange = '#FF4F00';
    const navigation = useNavigation();
    const downloadLink = 'https://drive.google.com/file/d/1Lq-nySV4A0Dy2-btnjmATAP6kiA6Lp4L/view?usp=sharing';
    const downloadScanner = 'https://drive.google.com/file/d/1-1GQ-ulrMSuYjqeFGZrk5IFtwmawxYM4/view?usp=sharing';


  return (
    <View style={{flex: 1, backgroundColor: '#edf1f2'}}>
        <View style={{zIndex: 12000}}>
            <Header/>
        </View>

        <View style={{width: width - 60, height: width / 2, backgroundColor: '#fcfcfc', marginTop: 30, alignSelf: 'center', borderRadius: 20, alignItems: 'center', justifyContent: 'center'}}>
       
        <View style={{alignSelf: 'center', alignItems: 'center', marginBottom: 10}}>
            <Text style={{fontWeight: 'bold'}}>APK file for event organisers</Text>
            <Text style={{fontSize: 12, alignSelf: 'center', textAlign: 'center'}}>Download to your android device and run to install</Text>
        </View>
       
       
        <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}} onPress={()=> Linking.openURL(downloadLink)}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Download</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>
        </View>

        <View style={{width: width - 60, height: width / 2, backgroundColor: '#fcfcfc', marginTop: 30, alignSelf: 'center', borderRadius: 20, alignItems: 'center', justifyContent: 'center'}}>
       
        <View style={{alignSelf: 'center', alignItems: 'center', marginBottom: 10}}>
            <Text style={{fontWeight: 'bold'}}>APK file for gate scanner</Text>
            <Text style={{fontSize: 12, alignSelf: 'center', textAlign: 'center'}}>Download to your android device and run to install</Text>
        </View>
       
       
        <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}} onPress={()=> Linking.openURL(downloadScanner)}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Download</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>
        </View>


    </View>
  )
}

export default Downloads

const styles = StyleSheet.create({})