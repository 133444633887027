import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Animated, ImageBackground, FlatList, ScrollView, Image, Pressable } from 'react-native'
import React, {useState, useEffect, useRef} from 'react';
import Header from './Components/Header';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'; 
import { FlashList } from "@shopify/flash-list";
import * as queries from './src/graphql/queries';
import { Video, ResizeMode } from 'expo-av';


import { useStateContext } from './src/contexts/ContextProvider';

import { Auth, API, graphqlOperation } from 'aws-amplify';
import { getUserData } from './src/graphql/queries';

const {width, height} = Dimensions.get('screen')



const HomeMain = () => {
  const {counting, setCounting, authed, setAuthed, authedUser, setAuthedUser} = useStateContext();
    const imageBack = 'https://images.unsplash.com/photo-1633465784393-d0c2da7cc9cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    const navigation = useNavigation();
    const joyus = 'https://lh3.googleusercontent.com/cWmb1yIGgUNIEYfX6Z8Lsg-X-5m4VjOt7nCS6UvyBy54Xad-_HRAu_wi6VbaTE4t-mPtb7vSPP9tAvAaaRSgafz5Zn16VfYBWw'
    const ourOrange = '#FF4F00';

    const scrollX = new Animated.Value(0);
    let position  = Animated.divide(scrollX, width)
    const [userInfo, setUserInfo] = useState();

    const [banners, setBanners] = useState([]);
    const [secOne, setSecOne] = useState([]);
    const [secTwo, setSecTwo] = useState([]);
    const [secThree, setSecThree] = useState([]);
    const [status, setStatus] = React.useState({});


    const [opt, setOpt] = useState('Categories')

    const mainY = useRef(new Animated.Value(0)).current;


    const video = React.useRef(null);

  const checkAuth = async()=>{


    try {
      await Auth.currentAuthenticatedUser().then((ef)=> {
        return
      })
    } catch (error) {
      console.error(error),
      setAuthed(false)
    }



    const userAuthenticated =  await Auth.currentAuthenticatedUser();
    const userSub = userAuthenticated.attributes.sub;

    
    try{
      await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
      const userd = ef.data.getUserData

        setAuthedUser({
          email: userd.email,
          firstName: userd.firstName
        })

      setUserInfo(userd),
      setAuthed(true)

      return
    }).catch((ee)=> {
      console.error(ee),
      setAuthed(false)
    })

} catch (e){
  console.error(e),
  setAuthed(false)
}

  }

  const listingBanners = async() => {
      

    const allTodos = await API.graphql({query: queries.listCampaigns, variables: 
        {
        filter : { and: [ 
            {active: {eq: true}},
            {approved: {eq: true}},
            {banner: {eq: true}},
            
        ]}
    } });
    
    const todos = allTodos.data.listCampaigns.items
    setBanners(todos)
    

    const allSecone = await API.graphql({query: queries.listCampaigns, variables: 
      {
      filter : { and: [ 
          {active: {eq: true}},
          {approved: {eq: true}},
          {secOne: {eq: true}},
          
      ]}
  } });
  
  const seconedata = allSecone.data.listCampaigns.items
  setSecOne(seconedata)


  const allSecTwo = await API.graphql({query: queries.listCampaigns, variables: 
    {
    filter : { and: [ 
        {active: {eq: true}},
        {approved: {eq: true}},
        {secTwo: {eq: true}},
        
    ]}
} });

const secTwodata = allSecTwo.data.listCampaigns.items
setSecTwo(secTwodata)


const allSecThree = await API.graphql({query: queries.listCampaigns, variables: 
  {
  filter : { and: [ 
      {active: {eq: true}},
      {approved: {eq: true}},
      {secThree: {eq: true}},
      
  ]}
} });

const secThreedata = allSecThree.data.listCampaigns.items
setSecThree(secThreedata)



}

  useEffect(() => {
    // showAnimMain()
    checkAuth()
    listingBanners()
       
  }, [userInfo])

  const showAnimMain = () => {
    Animated.parallel([
        Animated.spring(mainY, {
          toValue: 0,
          useNativeDriver: true
        })
    ]).start()
  }



    const categoryList = [
      {
        id: 1,
        name: 'Comedy',
        image: 'https://images.unsplash.com/photo-1543584756-8f40a802e14f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },
      {
        id: 2,
        name: 'Festival',
        image: 'https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },
      {
        id: 3,
        name: 'Music',
        image: 'https://images.unsplash.com/photo-1484972759836-b93f9ef2b293?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },
      {
        id: 4,
        name: 'Church',
        image: 'https://images.unsplash.com/photo-1548625149-fc4a29cf7092?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1064&q=80'
      },
      {
        id: 5,
        name: 'Fashion',
        image: 'https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1020&q=80'
      },
      {
        id: 6,
        name: 'Bus',
        image: 'https://images.unsplash.com/photo-1544620347-c4fd4a3d5957?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80'
      },
      {
        id: 7,
        name: 'Soccer',
        image: 'https://images.unsplash.com/photo-1560272564-c83b66b1ad12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1049&q=80'
      },
      {
        id: 8,
        name: 'Rugby',
        image: 'https://images.unsplash.com/photo-1512299286776-c18be8ed6a1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },{
        id: 9,
        name: 'Cricket',
        image: 'https://images.unsplash.com/photo-1593341646782-e0b495cff86d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80'
      },{
        id: 10,
        name: 'Kids',
        image: 'https://images.unsplash.com/photo-1593085512500-5d55148d6f0d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'
      },{
        id: 11,
        name: 'Lifestyle',
        image: 'https://images.unsplash.com/photo-1541904845547-0eaf866de232?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=941&q=80'
      },{
        id: 12,
        name: 'Theatre',
        image: 'https://images.unsplash.com/photo-1514306191717-452ec28c7814?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80'
      },{
        id: 13,
        name: 'Tourism',
        image: 'https://images.unsplash.com/photo-1519922639192-e73293ca430e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80'
      },{
        id: 14,
        name: 'Faith',
        image: 'https://images.unsplash.com/photo-1600468448005-67286e81341b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80'
      },{
        id: 15,
        name: 'Seminar',
        image: 'https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },{
        id: 16,
        name: 'Workshop',
        image: 'https://images.unsplash.com/photo-1659301254614-8d6a9d46f26a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },{
        id: 17,
        name: 'Charity',
        image: 'https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },{
        id: 18,
        name: 'Community',
        image: 'https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'
      },{
        id: 19,
        name: 'Health',
        image: 'https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2832&q=80'
      }
    ]





    const renderBanner = ({item}) => {
      return(
    <ImageBackground imageStyle={{borderRadius: 0}} style={{width: width - (width * 0), elevation: 0, height: height / 1.8, borderRadius: 0}}   source={{uri: item?.image}} >
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , justifyContent: 'center'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: '40%', padding: 10, alignItems: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>{item?.name}</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Feather name= 'info' color={'white'} size={15} />
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 10}}>FROM R{Number(item?.minAmount).toFixed(2)}</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity onPress={()=> navigation.navigate('EventDetails', {item: item})} style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>See Event</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
     </ImageBackground>
      )
    }


    const showBanner = () => {

      return(
        <View>
          <FlatList
                    data={banners}
                    keyExtractor={(item, index) => 'key'+index}
                    horizontal
                    pagingEnabled
                    scrollEnabled
                    snapToAlignment='center'
                    scrollEventThrottle={16}
                    decelerationRate={"normal"}
                    showsHorizontalScrollIndicator={false}
                    renderItem={renderBanner}
                    onScroll = {Animated.event(
                        [{nativeEvent: {contentOffset: {x: scrollX}}},
                        
                        ], {useNativeDriver: false}
                    )}


                 />

                 <Animated.View style={[styles.dotView,{marginTop: 15, backgroundColor: 'rgba(0,0,0,0.9)', position: 'absolute', zIndex: 9000, top: 40, right: 20}]}>
                    {banners.map((_, i) => {
                      let opacity = position.interpolate({
                            inputRange: [i - 1, i, i + 1],
                            outputRange: [0.2, 1, 0.2],
                            extrapolate: 'clamp'
                        })

                        return(
                            <Animated.View key={i}
                            style={{opacity, height: 7, width: 7, backgroundColor: 'black', margin: 8, borderRadius: 5, marginTop: -30}}
                              />
                        )
                    })}
                 </Animated.View>

                 </View>
      )
      

    }

    const imageCat = 'https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'

    const imageHed = 'https://images.unsplash.com/photo-1620503374956-c942862f0372?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'
    const secImage = 'https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80'
    const gamingCenter = 'https://images.unsplash.com/photo-1580234811497-9df7fd2f357e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2067&q=80'



    const smallfont = '#415066';
    const backColour = '#e3eefa';
    const secCol = '#91a5ba'

    const renderEventList = ({item, index})=> {

      return(
        <Pressable 
        onHoverIn={(e)=> {}}
        style={{marginRight: 10, elevation: 15, padding: 10, borderRadius: 10, backgroundColor: '#fcfcfc'}} onPress={()=> navigation.navigate('EventDetails', {item: item})}>
            <ImageBackground source={{uri: item.image}} imageStyle={{borderRadius: 10}} style={{width: 180, height: 180}}>

            </ImageBackground>
            <Text style={{fontSize: 14, marginTop: 10}}>{String(item.name).slice(0,23)}</Text>
            <Text style={{fontSize: 12, color: 'gray'}}>From R{Number(item.minAmount).toFixed(2)}</Text>
            <Text style={{fontSize: 10, color: 'gray'}}>{item.time}</Text>
            <View style={{backgroundColor: ourOrange, width: 35, height: 35, opacity: 0.99, position: 'absolute', top: 15, right:15, alignSelf: 'flex-end', padding: 5, borderRadius: 5, margin: 5, alignItems: 'center', justifyContent: 'center'}}>
              <Text style={{fontWeight: 'bold', color:'white', fontSize: 20}}>+</Text>
            </View>
        </Pressable>
      )
    }


    const renderCategory = ({item})=> {
      return(
        <TouchableOpacity onPress={()=> navigation.navigate('CategorySearch', {cat: item.name})} style={{height: 220, marginRight: 15, width: 200, borderWidth: 0, alignItems: 'center', justifyContent: 'center'}}>
          <View style={{height: 170, width: '100%'}}>
            <ImageBackground source={{uri: item?.image || imageCat}} imageStyle={{borderRadius: 10}} style={{height: '100%', width: '100%', borderRadius: 10}}>
            <View style={{position: 'absolute', bottom: 15, right: 15, height: 25, width: 25, borderRadius:30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
              <Feather name='arrow-right' size={15} color={'white'} />
            </View>
            </ImageBackground>
          </View>
          <Text style={{fontSize: 16, marginTop: 5, fontWeight: 'bold', opacity: 0.8}}>{item.name}</Text>
          
        </TouchableOpacity>
      )
    }



  return (
    <View style={{flex: 1, backgroundColor: backColour}}>
        <View style={{zIndex: 9000, position:'absolute'}}>
            <Header/>
        </View>
        <ScrollView style={{height: height}}>
        {/* hero */}
        <Animated.View style={{height: height/2.5, width: width, marginTop: 60, backgroundColor: '#edf1f2', flexDirection: 'row', transform: [{
          translateY: mainY
        }]}}>
          

          <ImageBackground style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}} 
            source={{uri: gamingCenter}}
          >
              <Animated.View style={{alignItems: 'center', opacity: 0.8, transform: [{rotate: '5deg'}]}}>
                <View style={{padding: 10, backgroundColor: ourOrange}}>
                <Text style={{fontSize: 35, fontWeight: 'bold', marginBottom: 10,color: '#fcfcfc'}}>EVENTS SOLD HOT</Text>
                </View>
                

                
              </Animated.View>
          </ImageBackground>
          
        </Animated.View>



        <View style={{height: 0, width: width, backgroundColor: '#fcfcfc'}} />
       
        
        <Animated.View style={{}}>
          <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Popular Categories</Text>

          <Animated.View style={{marginTop: 20}}>

            <FlatList
              horizontal
              showsHorizontalScrollIndicator={true}
              style={{paddingLeft: 25}}
              data={categoryList}
              renderItem={renderCategory}
            />


         
          </Animated.View>



        </Animated.View>

        <View style={{height: 20, width: width, backgroundColor: backColour}} />

       

        <View style={{flexDirection: 'row', backgroundColor: backColour}}>

        <View style={{width: '75%', backgroundColor: 'transparent', paddingBottom: 30 }}>

       

        <Animated.View style={{width :'100%'}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Trending in South Africa  <Image source={{uri: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png'}} style={{height: 20, width: 20}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secOne.slice(0,4)}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 10}}
          />
       
       
       
       
        </Animated.View>

       



        <Animated.View style={{width : '100%'}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Weekend Vibes<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secTwo.slice(0,4)}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 15}}
          />
       
       
       
       
        </Animated.View>


        </View>

        <Animated.View style={{width: '25%', padding: 15, backgroundColor: backColour }}>
      
      
        {/* <Pressable
        onPress={()=> navigation.navigate('Tv')}
        style={{width: '100%', marginTop: 0, marginBottom: 15, height: width / 5, alignItems: 'center', justifyContent: 'center', borderRadius: 15, backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
            <ImageBackground 
            style={{height: '100%', width: '100%'}}
            imageStyle={{borderRadius: 15}}
            source={{uri: 'https://images.unsplash.com/photo-1633454301558-0adccf60386c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=770&q=80'}}>
              <LinearGradient 
                colors={['transparent', 'rgba(0,0,0,0.9)']}
                style={{borderRadius: 15, width: '100%', height: '100%', justifyContent: 'flex-end', paddingBottom: 50, alignItems: 'center'}}
              >
                  <View style={{alignItems: 'center'}}>
                    <Text style={{fontWeight: 'bold', color: '#fcfcfc', fontSize: 20}}>Watch Free TV</Text>
                    <Text style={{fontWeight: 'bold', color: ourOrange, fontSize: 20,}}>Right Here</Text>
                  </View>
              </LinearGradient>
            </ImageBackground>
          </Pressable> */}

        <View style={{margin: 0, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10}}>
                                <Text style={{fontSize: 18, fontWeight: 'bold', marginBottom: 10}}>Quick Links</Text>

                                <View style={{height: 40, width: '100%', alignItems: 'center', paddingHorizontal: 3, justifyContent: 'space-between', backgroundColor: backColour, flexDirection: 'row', borderRadius: 10}}>
                                    <Pressable 
                                    onPress={()=> setOpt('Categories')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Categories'  ? '#fcfcfc' : 'tranasparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>PROFILE</Text>
                                    </Pressable>

                                    <Pressable 
                                    onPress={()=> setOpt('Educare')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'Educare'  ? '#fcfcfc' : 'tranasparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>NOTIFICATIONS</Text>
                                    </Pressable>

                                    <Pressable 
                                    onPress={()=> setOpt('General')}
                                    style={{height: 35, width: '30%', borderRadius: 10, backgroundColor: opt === 'General'  ? '#fcfcfc' : 'tranasparent', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={{fontSize: 10}}>GENERAL</Text>
                                    </Pressable>
                                </View>
        </View>

        <View style={{marginHorizontal: 0, backgroundColor: '#fcfcfc', padding: 20, borderRadius: 10, marginTop: 10, marginBottom: 20}}>
        <View style={{marginHorizontal: 5, backgroundColor: '#fcfcfc', padding: 10, borderRadius: 10}}>

{
    opt === 'Categories' && 
    <View>
        <Pressable 
                                        onPress={()=> {}}
                                        style={{height: 60, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                                            <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                                <Ionicons name= 'md-wallet-outline' size={30} />
                                            </View>

                                            <View style={{ marginLeft: 15}}>
                                                <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>Wallet</Text>
                                                <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Balance : ZAR0.00</Text>
                                            </View>

                                            <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                                                <Entypo name= 'dots-three-horizontal' size={15} />
                                            </Pressable>
        </Pressable>

      
    </View>
}

{
    opt === 'Educare' && 
    <View>
        <Pressable 
        onPress={()=> {}}
        style={{height: 55, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
            <View style={{height: 50, width: 50, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                <Ionicons name= 'notifications' size={25} />
            </View>

            <View style={{ marginLeft: 15}}>
                <Text style={{fontSize: 16, fontWeight: 'bold', opacity: 0.6}}>You have no notifications</Text>
            </View>

        </Pressable>

      
    </View>
}

{
    opt === 'General' && 
    <View>
       <Pressable style={{height: 60, width: '100%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
              <View style={{height: 55, width: 55, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                  <Ionicons name= 'md-wallet-outline' size={30} />
              </View>

              <View style={{ marginLeft: 15}}>
                  <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>Create Ticket</Text>
                  <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Chat and solve your problem</Text>
              </View>

              <Pressable style={{position: 'absolute', right: 10, top: 30}}>
                  <Entypo name= 'dots-three-horizontal' size={15} />
              </Pressable>
        </Pressable>

        
    </View>
}

</View>
        </View>


          {/* <Pressable 
          onPress={()=> navigation.navigate('Egaming')}
          style={{width: '100%', marginTop: 0, height: width / 5, alignItems: 'center', justifyContent: 'center', borderRadius: 15, backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
           
            <ImageBackground 
            style={{height: '100%', width: '100%'}}
            imageStyle={{borderRadius: 15}}
            source={{uri: 'https://images.unsplash.com/photo-1542751371-adc38448a05e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'}}>
              <LinearGradient 
                colors={['transparent', 'rgba(0,0,0,0.9)']}
                style={{borderRadius: 15, width: '100%', height: '100%', justifyContent: 'flex-end', paddingBottom: 50, alignItems: 'center'}}
              >
                  <View style={{alignItems: 'center'}}>
                    <Text style={{fontWeight: 'bold', color: '#fcfcfc', fontSize: 20}}>Unleash your inner</Text>
                    <Text style={{fontWeight: 'bold', color: ourOrange, fontSize: 20,}}>Gamer</Text>
                  </View>
              </LinearGradient>
            </ImageBackground>
          </Pressable> */}



        </Animated.View>

      


        

        <View>

        </View>
        </View>

       

        {/* <Animated.View style={{width :width}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>You might like </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secThree}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 0}}
          />
       
       
       
       
        </Animated.View> */}

        

        <Animated.View style={{height: height / 3, width: width, backgroundColor: '#1e1f24', marginTop: 25, padding: 25}}>
            <View style={{}}>
              <Image source={require('./assets/nblogo.png')} style={{width: 80, height: 60, resizeMode: 'contain'}} />
            </View>

            <View style={{alignSelf: 'center', width: width / 2}}>
            <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <TouchableOpacity
              onPress={()=> navigation.navigate('AboutUs')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>About Us</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={()=> navigation.navigate('listevent')} style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>List your event</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={()=> navigation.navigate('help')} style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Help</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity 
              onPress={()=> navigation.navigate('faqs')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>FAQs</Text>
              </TouchableOpacity>

              <TouchableOpacity 
              onPress={()=> navigation.navigate('TsnCs')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Ts n Cs</Text>
              </TouchableOpacity>

              <TouchableOpacity 
              onPress={()=> navigation.navigate('PrivacyPolicy')}
              style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Privacy Policy</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Chat to us</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Find Us</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={()=> navigation.navigate('Downloads')} style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Download Apps</Text>
              </TouchableOpacity>
            </View>
            
            <Text style={{fontSize: 12, color: '#fcfcfc', alignSelf: 'center', marginTop: 30}}>A product of REDSNAGISH (PTY)LTD</Text>
            <Text style={{fontSize: 10, color: '#fcfcfc', alignSelf: 'center', marginTop: 5}}>Developed by Sunday Creatives (Pty) Ltd</Text>
            </View>

           
        </Animated.View>

        </ScrollView>


    </View>
  )
}

export default HomeMain

const styles = StyleSheet.create({
  dotView: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
  }
})