import { View, Text, Dimensions, ImageBackground, Image, TouchableOpacity, Animated, ScrollView } from 'react-native'
import React, {useState, useEffect} from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import * as queries from '../src/graphql/queries';
const {width, height} = Dimensions.get('screen')
import { API, Auth, graphqlOperation } from 'aws-amplify';

const EventDetails = () => {

    const route = useRoute().params
    const routeInfo = route.item

    const navigation = useNavigation();
    const [events, setEvents] = useState([])

    const ourOrange = '#FF4F00';

   
    const gettingUserData = async()=> {

        const allTodos = await API.graphql({query: queries.listEvents, variables: {
            filter: {
                campaignId: {eq: routeInfo.id}
            }
        }})

        const eventsData = allTodos.data.listEvents.items
        setEvents(eventsData)

    }

    useEffect(() => {
      gettingUserData()
    }, [events])
    

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
      <ImageBackground source={{uri: routeInfo.image}} style={{height: height / 3, width: width}} >
        <View style={{height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', alignItems: 'center', justifyContent: 'center'}}>
            <Image 
                style={{height: 200, width: 200, borderWidth: 3, borderColor: '#fcfcfc'}}
                source={{uri: routeInfo.image}}
            />
        </View>
        <Animated.View style={{position: 'absolute', left: 0, top: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        
      </ImageBackground>
      <Animated.View style={{height: 60, width: width, bacc: 'rgba(0,0,0,0.5)', justifyContent: 'center', paddingHorizontal: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>{routeInfo.name || routeInfo.title}</Text>
        </Animated.View>
        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

        <ScrollView showsVerticalScrollIndicator={false} style={{backgroundColor: '#edf1f2',}}>

            <View style={{backgroundColor: '#fcfcfc', alignSelf: 'center', padding: 15, width: width / 2}}>

           

                <Animated.View style={{padding: 15}}>
                    <Text style={{fontSize: 14, fontWeight: 'bold'}}>Event Details</Text>
                    <Text style={{fontSize: 12, marginTop: 20, textAlign: 'justify'}}>{routeInfo.description}</Text>
                </Animated.View>

                <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

                <Animated.View style={{padding: 15}}>
                    <Text style={{fontSize: 14, fontWeight: 'bold'}}>Event Days</Text>
                    <Text style={{fontSize: 12, marginTop: 20, textAlign: 'justify'}}></Text>
                    {events.map((e, i)=> {
                        return(
                            <TouchableOpacity onPress={()=> navigation.navigate('EventTickets', {item: e, imagepass: routeInfo.image})} style={{height: 80, width: width / 3, marginBottom: 10, borderRadius: 12, flexDirection: 'row', borderWidth: 4, borderColor: '#edf1f2', paddingHorizontal: 10, alignItems: 'center'}}>
                                <Image source={{uri: routeInfo.image}} style={{height: 60, width: 60, borderRadius: 10}} />
                                <View style={{marginLeft: 25}}>
                                    <Text style={{fontSize: 12, fontWeight: 'bold'}}>{e.name}</Text>
                                    <View style={{marginTop: 5, flexDirection: 'row'}}>
                                        <Feather name='calendar' size={15} color={'gray'} />
                                        <Text style={{fontSize: 12, marginLeft: 15}}>{e.eventDate}</Text>
                                    </View>
                                </View>

                                <View style={{height: 30, width: 30, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange, position: 'absolute', bottom: 15, right: 15}}>
                                    <Feather name='arrow-right' size={15} color={'#fcfcfc'} />
                                </View>
                            </TouchableOpacity>
                        )
                    })}
                </Animated.View>    
            </View>
        </ScrollView>
    </View>
  )
}

export default EventDetails