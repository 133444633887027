import { View, Text, Dimensions, ImageBackground, Image, TouchableOpacity, Animated, ScrollView, Pressable } from 'react-native'
import React, {useState, useEffect} from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo, MaterialCommunityIcons } from '@expo/vector-icons'; 
import * as queries from '../src/graphql/queries';
import * as mutations from '../src/graphql/mutations';
const {width, height} = Dimensions.get('screen')
import { API, Auth, graphqlOperation } from 'aws-amplify';
import PaystackPop from '@paystack/inline-js';
import { getUserData } from '../src/graphql/queries';



const EventDetailsGaming = () => {

    const route = useRoute().params
    const routeInfo = route.item

    const navigation = useNavigation();
    const [events, setEvents] = useState([]);
    const [joinScreen, setJoinScreen] = useState(false);

    const walletAmount = 40
    const bookingfee  = 5.50
    const ServiceFee = (routeInfo.minAmount) * 0.04 
    const totalAmount = (routeInfo.minAmount) + bookingfee + ServiceFee - walletAmount

    const ourOrange = '#FF4F00';

    const emaila = 'sisopumi@gmail.com';
    const firstName = 'Siso';

   
    const gettingUserData = async()=> {

        const allTodos = await API.graphql({query: queries.listEvents, variables: {
            filter: {
                campaignId: {eq: routeInfo.id}
            }
        }})

        const eventsData = allTodos.data.listEvents.items
        setEvents(eventsData)

    }

    useEffect(() => {
      gettingUserData()
    }, [events])
    

    const paywithpaystack = (e) => {

       

        const paystack = new PaystackPop()
        paystack.newTransaction({
            key: "pk_test_deff22609582e8a41f931f8140f48728bf0b195a",
            // key: "pk_live_18e1b28183b4c600212f2d0cf2167d03e03f7c60",
            amount: (totalAmount)*100,
            email: emaila,
            firstname: firstName,
            lastname: '--',
            onSuccess(transaction){
                let message = `Payment Complete ! Reference ${transaction.reference}`
                alert(message)
                createOrder({payRef: transaction.reference})
            },
            oncancel(){
                alert('You have cancelled the transaction')
            }
        })
    }



    const createOrder = async({payRef}) => {


        const userData = await Auth.currentAuthenticatedUser();
        const senda = {
            qty: 1,
            eventId: 11111111,
            vendorId: 1111,
            image: routeInfo?.image,
            orderAmount: totalAmount,
            title: routeInfo?.name,
            userId: userData.attributes.sub,
            ownerId: 'e?.ownerId' || '1',
            eventDate: '',
            eventTime: '',
            eventLocation: 'egaming',
            paymentStatus: 'Paid',
            paymentReference: payRef,
            fullName: firstName,
            active: 'true',
            email: emaila,
            
        }


        

        const updateAvail = {
            
        }

        try {   
            await API.graphql(graphqlOperation(mutations.createOrder, { input: senda})).then((ed)=> {
                console.warn(ed.data.id)
                alert(ed.data)
                return

                const sendPlayer = {
                    id: userData.attributes.sub,
                    EgamesId: ''
                }

                
                API.graphql(graphqlOperation(mutations.createEPlayers, { input: sendPlayer})).then((ef)=> {
                    API.graphql(graphqlOperation(mutations.updateEgames, { input: updateAvail}))
                })
                
                
                
                navigation.navigate('MyTickets')
            })
        } catch (error) {
            console.error(error)
            return
        }



    }





  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>


        {joinScreen && 
            <View style={{height: height, width: width, zIndex: 9000, backgroundColor: 'rgba(0,0,0,0.6)', alignItems: 'center', justifyContent: 'center', position: 'absolute'}}>
                <View style={{width: width /2, height: height /2, backgroundColor: '#fcfcfc', borderRadius: 10, padding: 30}}>
                    <Pressable 
                    onPress={()=> {setJoinScreen(false)}}
                    style={{position: 'absolute', width: 35, height: 35, top: 15, left: 15, borderRadius: 40, alignItems: 'center', justifyContent: 'center', backgroundColor: 'red'}}>
                        <AntDesign name='close' color={'#fcfcfc'} size={25} />
                    </Pressable>

                    <View style={{padding: 20}}>

                    <View style={{ marginLeft: 15}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold', opacity: 0.6}}>Join Group</Text>
                        <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Paying the joining fee</Text>
                    </View>

                    <View style={{width: '100%',justifyContent: 'center', paddingTop: 40}}>
                        <Text style={{fontSize: 16, fontWeight: 'bold', opacity: 0.6}}>Invoice</Text>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <Text style={{width: '30%', marginRight: 50, fontSize: 12}}>Ticket</Text>
                            <Text style={{fontSize: 12}}>R{Number(routeInfo?.minAmount).toFixed(2)}</Text>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <Text style={{width: '30%', marginRight: 50, fontSize: 12}}>Service Fee</Text>
                            <Text style={{fontSize: 12}}>R{Number(ServiceFee).toFixed(2)}</Text>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <Text style={{width: '30%', marginRight: 50, fontSize: 12}}>Booking Fee</Text>
                            <Text style={{fontSize: 12}}>R{Number(bookingfee).toFixed(2)}</Text>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <Text style={{width: '30%', marginRight: 50, fontSize: 12}}>Wallet</Text>
                            <Text style={{fontSize: 12, color: 'green'}}>R{Number(walletAmount).toFixed(2)}</Text>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <Text style={{width: '30%', marginRight: 50, fontSize: 16, fontWeight: 'bold'}}>Due</Text>
                            <Text style={{fontSize: 12, fontSize: 16, fontWeight: 'bold'}}>R{Number(totalAmount).toFixed(2)}</Text>
                        </View>
                    </View>

                    <View style={{flexDirection: 'row', marginTop: 15}}>
                        {totalAmount >= 0.01 ? 
                        
                        <Pressable 
                        onPress={()=> paywithpaystack()}
                        style={{height: 45, borderWidth: 0, backgroundColor: ourOrange, borderRadius: 10, width: '20%', alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{fontSize: 12, color: '#fcfcfc', fontWeight: 'bold'}}>Pay R{Number(totalAmount).toFixed(2)}</Text>
                        </Pressable> :
                        <Pressable style={{height: 45, borderWidth: 0, backgroundColor: ourOrange, borderRadius: 10, width: '20%', alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{fontSize: 12, color: '#fcfcfc', fontWeight: 'bold'}}>Join</Text>
                        </Pressable>
                        } 

                        <Pressable onPress={()=> setJoinScreen(false)} style={{height: 45, marginLeft: 20, borderWidth: 1, borderColor: ourOrange, borderRadius: 10, width: '20%', alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={{fontSize: 12, color: ourOrange, fontWeight: 'bold'}}>Cancel</Text>
                        </Pressable> 



                    </View>



                    </View>


                </View>   
            </View>
        }







      <ImageBackground source={{uri: routeInfo.image}} style={{height: height / 3, width: width}} >
        <View style={{height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', alignItems: 'center', justifyContent: 'center'}}>
            <Image 
                style={{height: 200, width: 200, borderWidth: 3, borderColor: '#fcfcfc'}}
                source={{uri: routeInfo.image}}
            />
        </View>
        <Animated.View style={{position: 'absolute', left: 0, top: 0, zIndex: 9000}}>
            <TouchableOpacity onPress={()=> navigation.goBack()} style={{height: 60, width: 60, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center'}}>
                <Feather name='arrow-left' size={30} color={'#fcfcfc'}  />
            </TouchableOpacity>
        </Animated.View>
        
      </ImageBackground>
      <Animated.View style={{height: 60, width: width, bacc: 'rgba(0,0,0,0.5)', justifyContent: 'center', paddingHorizontal: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>{routeInfo.name || routeInfo.title}</Text>
        </Animated.View>
        <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

        <ScrollView showsVerticalScrollIndicator={false} style={{backgroundColor: '#edf1f2',}}>

            <View style={{backgroundColor: '#fcfcfc', alignSelf: 'center', padding: 15, width: width / 2}}>

           

                <Animated.View style={{padding: 15}}>
                    <Text style={{fontSize: 14, fontWeight: 'bold'}}>Event Details</Text>
                    <Text style={{fontSize: 12, marginTop: 20, textAlign: 'justify'}}>{routeInfo.description}</Text>
                </Animated.View>

                

                <View style={{flexDirection: 'row', padding: 15, justifyContent: 'space-between'}}>
                    <Pressable 
                    onPress={()=> {}}
                    style={{height: 50, marginTop: 10, width: '45%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 45, width: 45, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'gamepad' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6}}>Date</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>{routeInfo.date}</Text>
                        </View>

                        
                    </Pressable>

                    <Pressable 
                    onPress={()=> {}}
                    style={{height: 50, marginTop: 10, width: '45%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 45, width: 45, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'gamepad' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6}}>Time</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>{routeInfo.time}</Text>
                        </View>

                        
                    </Pressable>
                </View>

                <View style={{flexDirection: 'row', padding: 15, marginTop: -30, justifyContent: 'space-between'}}>
                    <Pressable 
                    onPress={()=> {}}
                    style={{height: 50, marginTop: 10, width: '45%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 45, width: 45, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'gamepad' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6}}>Players</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>{routeInfo.numPlayers}</Text>
                        </View>

                        
                    </Pressable>

                    <Pressable 
                    onPress={()=> {}}
                    style={{height: 50, marginTop: 10, width: '45%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 45, width: 45, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'gamepad' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6}}>Prize</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>ZAR{routeInfo.prize}</Text>
                        </View>

                        
                    </Pressable>
                </View>

                <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />
                    <Animated.View style={{padding: 15}}>
                        <Text style={{fontSize: 14, fontWeight: 'bold'}}>Rules</Text> 

                        <View style={{flexDirection: 'row', padding: 15, justifyContent: 'space-between'}}>
                    <Pressable 
                    onPress={()=> {}}
                    style={{height: 50, marginTop: 10, width: '45%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 45, width: 45, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'gamepad' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6}}>Time</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Avail 10 min before start time</Text>
                        </View>

                        
                    </Pressable>

                    <Pressable 
                    onPress={()=> {}}
                    style={{height: 50, marginTop: 10, width: '45%', borderRadius: 10, flexDirection: 'row', alignItems: 'center'}}>
                        <View style={{height: 45, width: 45, backgroundColor: 'rgba(247, 244, 235, 0.8)', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                            <MaterialCommunityIcons name= 'gamepad' size={30} />
                        </View>

                        <View style={{ marginLeft: 15}}>
                            <Text style={{fontSize: 12, fontWeight: 'bold', opacity: 0.6}}>Fair Play</Text>
                            <Text style={{fontSize: 12, color: 'gray', opacity: 0.8}}>Only one user permited</Text>
                        </View>
                        
                    </Pressable>
                </View>  
                    </Animated.View>

                     
                <View style={{height: 10, width: width, backgroundColor: '#edf1f2'}} />

                <Animated.View style={{padding: 15}}>
                    <Text style={{fontSize: 14, fontWeight: 'bold'}}>Join</Text>
                    <Text style={{fontSize: 12, marginTop: 20, textAlign: 'justify'}}></Text>

                    <TouchableOpacity onPress={()=> {setJoinScreen(true)}} style={{height: 80, width: width / 3, marginBottom: 10, borderRadius: 12, flexDirection: 'row', borderWidth: 4, borderColor: '#edf1f2', paddingHorizontal: 10, alignItems: 'center'}}>
                                <Image source={{uri: routeInfo.image}} style={{height: 60, width: 60, borderRadius: 10}} />
                                <View style={{marginLeft: 25}}>
                                    <Text style={{fontSize: 12, fontWeight: 'bold'}}>{routeInfo?.name}</Text>
                                    <View style={{marginTop: 5, flexDirection: 'row'}}>
                                        <Ionicons name= 'game-controller' size={15} color={'gray'} />
                                        <Text style={{fontSize: 12, marginLeft: 15}}>- 1 Player - R{Number(routeInfo.minAmount).toFixed(2)}</Text>
                                    </View>
                                </View>

                                <View style={{height: 30, width: 30, borderRadius: 5, alignItems: 'center', justifyContent: 'center', backgroundColor: ourOrange, position: 'absolute', bottom: 15, right: 15}}>
                                    <Feather name='arrow-right' size={15} color={'#fcfcfc'} />
                                </View>
                            </TouchableOpacity>
                </Animated.View>    
            </View>
        </ScrollView>
    </View>
  )
}

export default EventDetailsGaming