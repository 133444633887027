import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated, FlatList, ImageBackground } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'; 
import Header from '../Components/Header';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../src/graphql/queries';
import * as mutations from '../src/graphql/mutations';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';
import { useNavigation } from '@react-navigation/native';
import LoginFlow from '../Components/LoginFlow';
import PaystackPop from '@paystack/inline-js';
import { getUserData } from '../src/graphql/queries';
import CryptoJS from "react-native-crypto-js";
import { JSHash, JSHmac, CONSTANTS } from "react-native-hash";
import { Linking } from 'react-native'
import uuid from 'react-native-uuid';

const Payment = () => {
    const [signature, setSignature] = useState('01');
    const [date, setDate] = useState('')
    
    const {counting, setCounting, authed, setAuthed} = useStateContext();
    const navigation = useNavigation();
    
    const [spinning, setSpinning] = useState(false);
    const [showLoaded, setShowLoaded] = useState(false);
    const [cartProducts, setCartProducts] = useState([]);
    const [userData, setUserData] = useState()
    const [newAmount, setNewAmount] = useState()

    const ourOrange = '#FF4F00';

    const [redirectLink, setRedirectLink] = useState('');

    const emaila = userData?.email
    const firstName = userData?.firstName

    let totalPrice = 1;


    totalPrice = cartProducts?.reduce((summedPrice, product) => 
    (summedPrice + (product?.amount || 0) * product?.qty ), 
    0);

     const serviceFee = 9.99    
     const bookingFee = 5.50

     const newTotal = parseFloat(totalPrice + serviceFee)

    const fetchingTickets = async()=> {

  
        if(authed){
          const userIdd = await Auth.currentAuthenticatedUser({bypassCache: true})
    
            try {
                const todoData = await API.graphql({query: queries.listCarts, variables:  
                    {filter :   { userId: 
                            {eq: 
                                (userIdd?.attributes.sub)
                            },
                            }
                     }
                })
                    const todos = todoData.data.listCarts.items
                    setCartProducts(todos)
            } catch (error) {
                console.error(error)
                navigation.goBack()
            }
    
         

          try {
            const userSub =  userIdd?.attributes.sub  
            await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((eff)=> {
                const thedata = eff.data.getUserData

                setUserData(thedata)

            })

          } catch (error) {
            console.error(error)
            navigation.goBack()
          }




        }
    
    
        
    }

    const autoCreateOrder = async()=> {
        if(totalPrice === 0){
            createFreeOrder()
        }
    }

    
    
    
    
    const sec_key = '0afc7b7284b146feb005a4408c1dfdb1'


    let hmac_encoded_str = ''



    useEffect(()=> {
        fetchingTickets();

        const totalPriceL = parseFloat( 
            parseFloat( cartProducts?.reduce((summedPrice, product) => 
        (summedPrice + (product?.amount || 0) * product?.qty ), 0)))



        
        if(signature === '01' && totalPriceL){

            setDate(new Date().valueOf()) 
            const newId = uuid.v4();
            const servFee = parseFloat((totalPriceL * 0.029) + 1)
            const newTot = parseFloat(totalPriceL + servFee)

         

            const stringIn = String('amount' +totalPriceL+'authentication.entityId8ac7a4c989d20e450189d4f6d1cb0303currencyZARmerchantTransactionId' + String(newId) + 'nonce' + String(newId) + 'paymentTypeDBshopperResultUrlhttps://www.ticketday.co.za')


            try {
                JSHmac(stringIn, sec_key, CONSTANTS.HmacAlgorithms.HmacSHA256)
            .then(hash => {
            hmac_encoded_str = hash
            newSign = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(hmac_encoded_str))
            console.log(hash)
            
            setSignature(hash)

            const options = {   
                method: 'POST',
                headers: {
                  accept: 'application/json',
                  Referer: 'https://ticketday.co.za',
                  'content-type': 'application/json'
                },
                body: JSON.stringify({
                  paymentType: 'DB',
                  currency: 'ZAR',
                  signature: hash,
                  merchantTransactionId: newId,
                  amount: String(totalPriceL),
                  nonce: newId,
                  shopperResultUrl: 'https://www.ticketday.co.za',
                  'authentication.entityId': '8ac7a4c989d20e450189d4f6d1cb0303',
                })
              };

            fetch('https://testsecure.peachpayments.com/checkout/initiate', options)
            .then(response => response.json())
            .then(response => {setRedirectLink(response.redirectUrl), console.log("MM ",response.redirectUrl)})
            .catch(err => console.error(err));


                
            }).catch(e => {console.log(e)});
            } catch (error) {
                console.err(error)
            }

        } 
            
        

    }, [cartProducts, authed])



    if(!authed){
        return (
          <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
            <View style={{padding: 20}}>
              <TouchableOpacity onPress={()=> navigation.goBack()}>
                <Feather size={30} name='arrow-left' />
              </TouchableOpacity>
            </View>
            <LoginFlow/>
          </View>
        )
        
         
      }


    // const paywithpaystack = (e) => {
    //     const paystack = new PaystackPop()
    //     paystack.newTransaction({
    //         key: "pk_live_18e1b28183b4c600212f2d0cf2167d03e03f7c60",
    //         amount: (totalPrice + serviceFee)*100,
    //         email: emaila,
    //         firstname: firstName,
    //         lastname: '--',
    //         onSuccess(transaction){
    //             let message = `Payment Complete ! Reference ${transaction.reference}`
    //             createOrder({payRef: transaction.reference})
    //         },
    //         oncancel(){
    //             alert('You have cancelled the transaction')
    //         }
    //     })
    // }


    const createOrder  = async({payRef})=> {


        const userData = await Auth.currentAuthenticatedUser();

        try {

            await Promise.all( 
                cartProducts?.map((e) => {
                 

                    const senda = {
                        qty: e?.qty,
                        eventId: e?.eventId,
                        seatId: e?.seatId,
                        vendorId: e?.vendorId,
                        image: e?.image,
                        orderAmount: (e?.amount === 0 ? 0 : e?.amount),
                        title: e?.title,
                        userId: userData.attributes.sub,
                        campaignId: e?.campaignId,
                        ownerId: e?.ownerId || '1',
                        eventDate: '',
                        eventTime: '',
                        eventLocation: '',
                        paymentStatus: 'Paid',
                        paymentReference: payRef || 0,
                        fullName: firstName,
                        country: 'South Africa',
                        active: 'true',
                        email: emaila,
                        
                    }

                    

                    const deletee = {
                        id: e?.id,
                     
                    }

                    const blueza = async() => {

                        await API.graphql(graphqlOperation(mutations.createOrder, { input: senda})).then((ef)=>{

                            API.graphql(graphqlOperation(getUserData, { id: e?.vendorId})).then((ef)=> {
                                const userd = ef.data.getUserData

                                
                                const userUpd = {
                                    id: e?.vendorId,
                                    pendingBalance: ((userd?.pendingBalance || 0 ) + (e?.amount|| 1) )
                                }        
                                
                                
                                API.graphql(graphqlOperation(mutations.updateUserData, {input: userUpd})).then((esdd)=> {
                                    API.graphql(graphqlOperation(mutations.deleteCart, { input: deletee})).then((efd)=>{
                                        navigation.navigate('MyTickets')
                                    })  
                                })
                            
                              })
                           
                        })}  


                        blueza();

                }
 
            )
            ).then((ee)=> {
                alert('order complete')
            })
        } catch (error) {
            console.error(error)
         }

    }

    const createFreeOrder = async()=> {
        const userData = await Auth.currentAuthenticatedUser();

        try {
            await Promise.all( 
                cartProducts?.map((e) => {

                    const senda = {
                        qty: e?.qty,
                        eventId: e?.eventId,
                        seatId: e?.seatId,
                        vendorId: e?.vendorId,
                        image: e?.image,
                        title: e?.title,
                        userId: userData.attributes.sub,
                        campaignId: e?.campaignId,
                        ownerId: e?.ownerId || '1',
                        eventDate: '',
                        eventTime: '',
                        eventLocation: '',
                        paymentStatus: 'Paid',
                        fullName: firstName,
                        country: 'South Africa',
                        active: 'true',
                        email: emaila,

                    }

                    const deletee = {
                        id: e?.id,
                     
                    }



                    const blueza = async() => {

                        await API.graphql(graphqlOperation(mutations.createOrder, { input: senda})).then((ef)=>{

                            API.graphql(graphqlOperation(getUserData, { id: e?.vendorId})).then((ef)=> {
                                const userd = ef.data.getUserData

                                
                                const userUpd = {
                                    id: e?.vendorId,
                                    pendingBalance: (0)
                                }        
                                
                                
                                API.graphql(graphqlOperation(mutations.updateUserData, {input: userUpd})).then((esdd)=> {
                                    API.graphql(graphqlOperation(mutations.deleteCart, { input: deletee})).then((efd)=>{
                                        navigation.navigate('MyTickets')
                                    })  
                                })
                            
                              })
                           
                        })}  


                        blueza()


                })
            )
        }catch (error) {
            console.error(error)
         }



    }


    const paywithVoucher =()=> {

       try {
        Linking.openURL(redirectLink)
       } catch (error) {
        console.error(error)
       }  

    }


  return (
    <View style={{flex: 1, backgroundColor: '#edf1f2'}}>
      <View style={{zIndex: 19000}}>
         <Header/>
        </View>

        {authed &&
            <View style={{height: height / 2, width: width / 3, backgroundColor: '#fcfcfc', padding: 25, marginTop: 5, alignSelf: 'center'}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 15, marginLeft: 0}}>Payment Summary</Text>

                    <View style={{marginTop: 20}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{width: '40%'}}>
                                <Text style={{fontSize: 13}}>Cart Amount</Text>
                            </View>

                            <View style={{marginLeft: 30}}>
                                <Text style={{fontSize: 13}}>R{Number(totalPrice).toFixed(2)}</Text>
                            </View>
                        </View>

                        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                            <View style={{width: '40%'}}>
                                <Text style={{fontSize: 13}}>Service Fee</Text>
                            </View>

                            <View style={{marginLeft: 30}}>
                                <Text style={{fontSize: 13}}>R{Number(serviceFee).toFixed(2)}</Text>
                            </View>

                        
                        </View>

                     

                        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                            <View style={{width: '40%'}}>
                                <Text style={{fontSize: 13}}>Order Amount</Text>
                            </View>

                            <View style={{marginLeft: 30}}>
                                <Text style={{fontSize: 13}}>R{Number(totalPrice === 0 ? 0 :  (serviceFee + totalPrice)).toFixed(2)}</Text>
                            </View>

                        
                        </View>

                     

                        <View style={{marginTop: 80}}>

                            {
                                totalPrice === 0 ? 

                                <TouchableOpacity onPress={()=> {autoCreateOrder()}} style={{height: 45, width: '50%', borderRadius: 5, flexDirection: 'row', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', alignSelf: 'center'}}>
                                <Feather name= 'lock' color={'white'} size={15} />
                                <Text style={{fontWeight: 'bold', fontSize: 12, color: 'white', marginLeft: 15}}>Get R0.00 Ticket</Text>
                                </TouchableOpacity> :

                                <View style={{width: '100%'}}>

                                    {redirectLink ? 
                                    <TouchableOpacity onPress={()=> paywithVoucher()} style={{height: 45, width: '60%', marginLeft: 0, borderRadius: 5, flexDirection: 'row', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', alignSelf: 'center'}}>
                                        <Feather name= 'lock' color={'white'} size={15} />
                                        <Text style={{fontWeight: 'bold', fontSize: 12, color: 'white', marginLeft: 15}}>Pay With Peach Payments</Text>
                                    </TouchableOpacity> : 
                                    <View style={{height: 45, width: '60%', marginLeft: 15, borderRadius: 5, opacity: 0.5, flexDirection: 'row', backgroundColor: 'gray', alignItems: 'center', justifyContent: 'center', alignSelf: 'center'}}>
                                    <Feather name= 'lock' color={'white'} size={15} />
                                    <Text style={{fontWeight: 'bold', fontSize: 12, color: 'white', marginLeft: 15}}>Loading Peach Payments</Text>
                                    </View> 

                                    }
                                </View>
                            }
                           
                            {totalPrice !== 0 &&
                            <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                        <Image source={{uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThoG-z9MYA4a9bygy4vJyHAusLeP5DYmLa95sZ_3OM&s'}}  style={{height: 45, width: 100, resizeMode: 'center', alignSelf: 'center', marginTop: 5, marginLeft: 15}}/>
                            </View>                                
                            }
                        
                        </View>


                    </View>

            </View>
        }




    </View>
  )
}

export default Payment

const styles = StyleSheet.create({})