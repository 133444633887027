import {  View, Dimensions, Animated, ActivityIndicator, Image } from 'react-native';
import { useState, useEffect, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeMain from './HomeMain';
import EventDetails from './Screens/EventDetails';
import EventTickets from './Screens/EventTickets';
import MyTickets from './Screens/MyTickets';
import TicketDetails from './Screens/TicketDetails';
import Cart from './Screens/Cart';
import Payment from './Screens/Payment';
import CategorySearch from './Screens/CategorySearch';
import Downloads from './Screens/Downloads';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import TsnCs from './Screens/TsnCs';
import AboutUs from './Screens/AboutUs';
import Tv from './Screens/Tv';
import * as Linking from 'expo-linking';

const {width} = Dimensions.get('screen')
const Stack = createNativeStackNavigator();
import { ContextProvider } from './src/contexts/ContextProvider';



import Profile from './Screens/Profile';
import Search from './Screens/Search';
import TvStream from './Screens/TvStream';
import LiveStreams from './Screens/LiveStreams';
import Egaming from './Screens/Egaming';
import EventDetailsGaming from './Screens/EventDetailsGaming';
import GamingHub from './Screens/GamingHub';


import { Amplify } from 'aws-amplify';
import awsExports from './src/aws-exports';
import FAQs from './Screens/FAQs';
import Help from './Screens/Help';
import ListEvent from './Screens/ListEvent';
Amplify.configure(awsExports);



export default function App() {

  const [choose,setChoose] = useState('Main');

  useEffect(() => {

    if(width <= 750){
      Linking.openURL('http://m.ticketday.co.za')
      return
    }
    

    Animated.timing(opacityE, {
      toValue: 1,
      duration: 900,
      delay: 350,
      useNativeDriver: true,
    }).start();

    Animated.timing(opacityF, {
      toValue: 1,
      duration: 900,
      delay: 1500,
      useNativeDriver: true,
    }).start();


    setTimeout(() => {
      setChoose('done')
    }, 6000);



  }, [])
  
 

    const opacityE = useRef(new Animated.Value(0)).current;
    const opacityF = useRef(new Animated.Value(0)).current;


  if(choose === 'Main'){


      return(
        <Animated.View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center', opacity: opacityE}}>
               <Image source={require('./assets/dar.png')} style={{width: width / 2, height: 60, resizeMode: 'contain'}} /> 
               <Animated.View style={{opacity: opacityF}}>
                 <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />
               </Animated.View>     
        </Animated.View>
      )
  }
  
  return (
    <ContextProvider>
      <View style={{flex: 1}}>

      
      
<NavigationContainer>
<Stack.Navigator screenOptions={{
   headerShown: false,
}}
initialRouteName="Home">
  <Stack.Screen name="Home" component={HomeMain} />
  <Stack.Screen name="Search" component={Search} />
  <Stack.Screen name="Profile" component={Profile} />
  <Stack.Screen name="EventDetails" component={EventDetails} />
  <Stack.Screen name="EventTickets" component={EventTickets} />
  <Stack.Screen name="MyTickets" component={MyTickets} />
  <Stack.Screen name="TicketDetails" component={TicketDetails} />
  <Stack.Screen name="Cart" component={Cart} />
  <Stack.Screen name="Payment" component={Payment} />
  <Stack.Screen name="CategorySearch" component={CategorySearch} />
  <Stack.Screen name="Downloads" component={Downloads} />
  <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
  <Stack.Screen name="TsnCs" component={TsnCs} />
  <Stack.Screen name="AboutUs" component={AboutUs} />
  <Stack.Screen name="Tv" component={Tv} />
  <Stack.Screen name="TvStream" component={TvStream} />
  <Stack.Screen name="LiveStreams" component={LiveStreams} />
  <Stack.Screen name="Egaming" component={Egaming} />
  <Stack.Screen name="EventDetailsGaming" component={EventDetailsGaming} />
  <Stack.Screen name="GamingHub" component={GamingHub} />
  <Stack.Screen name="faqs" component={FAQs} />
  <Stack.Screen name="help" component={Help} />
  <Stack.Screen name="listevent" component={ListEvent} />
</Stack.Navigator>
</NavigationContainer>
      </View>
    </ContextProvider>
    
  );
}


